import axios from "axios";
import cookie from "vue-cookie";

class HttpClient {
    constructor() {
        this.instance = axios.create({
            baseURL:
                process.env.VUE_APP_API_BASE_URL ||
                "http://localhost:8081/inout-rest/",
            timeout: 30000, // 30 detik timeout
            headers: {
                "Content-Type": "application/json",
            },
        });

        this.setupInterceptors();
    }

    setupInterceptors() {
        // Request interceptor
        this.instance.interceptors.request.use(
            (config) => {
                // Tambahkan token ke header jika ada
                const token = cookie.get("token");
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

                // Tambahkan appsId ke header jika ada
                const appsId = cookie.get("appsId");
                if (appsId) {
                    config.headers.appsId = appsId;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Response interceptor
        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                // Handle error responses
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            // Handle unauthorized
                            cookie.delete("token");
                            cookie.delete("appsId");
                            window.location.href = "/login";
                            break;
                        case 403:
                            // Handle forbidden
                            console.error("Access forbidden");
                            break;
                        case 500:
                            // Handle server error
                            console.error("Server error");
                            break;
                        default:
                            console.error("HTTP Error:", error.response.status);
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    // Helper methods
    get(url, config = {}) {
        return this.instance.get(url, config);
    }

    post(url, data = {}, config = {}) {
        return this.instance.post(url, data, config);
    }

    put(url, data = {}, config = {}) {
        return this.instance.put(url, data, config);
    }

    delete(url, config = {}) {
        return this.instance.delete(url, config);
    }

    // Method untuk mengubah headers
    setHeader(key, value) {
        this.instance.defaults.headers.common[key] = value;
    }

    // Method untuk menghapus headers
    removeHeader(key) {
        delete this.instance.defaults.headers.common[key];
    }
}

export default new HttpClient();
