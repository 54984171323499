import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

let db;

try {
    const missingConfigs = Object.entries(firebaseConfig)
        .filter(([, value]) => !value)
        .map(([key]) => key);

    if (missingConfigs.length > 0) {
        throw new Error(
            `Missing Firebase configs: ${missingConfigs.join(", ")}`
        );
    }

    const app = initializeApp(firebaseConfig);

    db = getFirestore(app);
} catch (error) {
    console.error("❌ Firebase initialization failed:", error);
    throw error;
}

export { db };
