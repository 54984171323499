import IAuthService from "../interfaces/IAuthService";
import mockAdmin from "@/mock/admin.json";
import mockUsers from "@/mock/users.json";
import cookie from "vue-cookie";

export default class MockAuthService extends IAuthService {
    async login(username, password) {
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Cek di admin dulu
            let user = mockAdmin.admins.find(
                (u) => u.username === username && u.password === password
            );
            let userType = "admin";

            // Jika tidak ada di admin, cek di users
            if (!user) {
                user = mockUsers.users.find(
                    (u) => u.username === username && u.password === password
                );
                userType = "user";
            }

            if (user) {
                const token = btoa(
                    `${username}:${userType}:${user.id}:${new Date().getTime()}`
                );
                cookie.set("token", token);
                cookie.set("userType", userType);
                cookie.set("userId", user.id);

                return {
                    success: true,
                    data: {
                        rows: [user],
                        token,
                        userType,
                        userId: user.id,
                    },
                };
            }

            return { success: false, error: "Invalid username or password" };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getProfile() {
        const token = cookie.get("token");
        if (!token) return { success: false, error: "No token found" };

        const [username, userType] = atob(token).split(":");

        let user;
        if (userType === "admin") {
            user = mockAdmin.admins.find((u) => u.username === username);
            return {
                success: true,
                data: {
                    data: {
                        display_name: user.display_name,
                        jobTitle: user.jobTitle,
                    },
                },
            };
        } else {
            user = mockUsers.users.find((u) => u.username === username);
            return {
                success: true,
                data: {
                    data: {
                        display_name: user.name,
                        jobTitle: {
                            job_title_name: user.position,
                        },
                    },
                },
            };
        }
    }

    async getRole() {
        try {
            const token = cookie.get("token");
            if (!token) throw new Error("No token found");

            const [username, userType] = atob(token).split(":");

            if (userType === "admin") {
                const admin = mockAdmin.admins.find(
                    (u) => u.username === username
                );
                return {
                    success: true,
                    data: {
                        rows: admin.roles,
                    },
                };
            } else {
                const user = mockUsers.users.find(
                    (u) => u.username === username
                );
                return {
                    success: true,
                    data: {
                        rows: [user.role],
                    },
                };
            }
        } catch (error) {
            return { success: false, error: "Failed to get roles" };
        }
    }

    async getMenu() {
        return { success: true, data: [] };
    }

    async getApps() {
        return { success: true, data: [] };
    }

    async getPrivilege() {
        return { success: true, data: [] };
    }
}
