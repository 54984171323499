import cookie from "vue-cookie";

export const AUTH_ROUTES = {
    LOGIN: "/login",
    HOME: "/tracker/dashboard",
};

export function authGuard(to, from, next) {
    const token = cookie.get("token");
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isGuestRoute = to.matched.some((record) => record.meta.guest);

    // Pengguna belum login mencoba mengakses halaman yang membutuhkan auth
    if (requiresAuth && !token) {
        return next({
            path: AUTH_ROUTES.LOGIN,
            query: { redirect: to.fullPath },
        });
    }

    // Pengguna sudah login mencoba mengakses halaman guest (login/register)
    if (isGuestRoute && token) {
        return next(AUTH_ROUTES.HOME);
    }

    next();
}

export function handleError(error, router) {
    if (error.response?.status === 401) {
        cookie.delete("token");
        router.push(AUTH_ROUTES.LOGIN);
    }
    return Promise.reject(error);
}
