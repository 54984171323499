<template>
    <v-snackbar
        v-model="visible"
        :timeout="getTimeout"
        :color="snackbarColor"
        top
    >
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="visible = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SnackbarAlert",
        data() {
            return {
                visible: false,
                snackbarMessage: "",
                snackbarColor: "info",
                timeouts: {
                    error: 6000,
                    success: 3000,
                    info: 4000,
                    warning: 5000,
                },
            };
        },
        computed: {
            getTimeout() {
                return this.timeouts[this.snackbarColor] || 3000;
            },
        },
        created() {
            // Mendengarkan perubahan dari alert plugin
            this.$alert.$watch("message", (newVal) => {
                if (newVal) {
                    this.snackbarMessage = newVal;
                    this.snackbarColor = this.$alert.type;
                    this.visible = true;
                    this.$alert.message = ""; // Reset pesan
                }
            });
        },
    };
</script>
