export default {
    bind(el, binding) {
        if (binding.value) {
            const label = el.querySelector("label");
            if (label && !label.innerHTML.includes("<span")) {
                label.innerHTML += '<span style="color: red"> *</span>';
            }
        }
    },
};
