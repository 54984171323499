import { format, isToday, isYesterday } from "date-fns";

export const dateTime = {
    // Convert Firestore timestamp ke Date object
    fromFirestoreTimestamp(timestamp) {
        if (!timestamp) return null;
        return new Date(timestamp.seconds * 1000);
    },

    // Format waktu 24 jam (14:30)
    formatTime24(date) {
        if (!date) return "";
        return format(new Date(date), "HH:mm");
    },

    // Cek apakah tanggal adalah hari ini
    isToday(date) {
        if (!date) return false;
        return isToday(new Date(date));
    },

    // Cek apakah tanggal adalah kemarin
    isYesterday(date) {
        if (!date) return false;
        return isYesterday(new Date(date));
    },

    // Format tanggal pendek (20/01/2024)
    formatShortDate(date) {
        if (!date) return "";
        return format(new Date(date), "dd/MM/yyyy");
    },

    // Format tanggal dan waktu (20/01/2024 14:30)
    formatDateTimeShort(date) {
        if (!date) return "";
        return format(new Date(date), "dd/MM/yyyy HH:mm");
    },

    // Format waktu HH:MM (contoh: 14:30)
    formatHHMM(date) {
        if (!date) return "";
        return format(new Date(date), "HH:mm");
    },

    // Format tanggal untuk divider chat
    formatChatDate(date) {
        if (!date) return "";

        if (isToday(new Date(date))) {
            return "Today";
        } else if (isYesterday(new Date(date))) {
            return "Yesterday";
        } else {
            return format(new Date(date), "dd MMMM yyyy");
        }
    },

    // Format tanggal dari ISO string ke YYYY-MM-DD
    formatToYYYYMMDD(dateString) {
        if (!dateString) return null;
        return format(new Date(dateString), "yyyy-MM-dd");
    },

    // Format tanggal ke ISO string
    formatToISOString(dateString) {
        if (!dateString) return null;
        return new Date(dateString).toISOString();
    },

    // Format tanggal ke format "MMM DD, YYYY hh:mm A" (Dec 30, 2024 10:03 PM)
    formatFullDateTime(dateString) {
        if (!dateString) return "";
        return format(new Date(dateString), "MMM dd, yyyy hh:mm a");
    },

    // Format tanggal ke YYYY-MM-DD hh:mm:ss
    formatToYYYYMMDDHHMMSS(dateString) {
        if (!dateString) return null;
        return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
    },

    // Format tanggal ke format "MMM DD, YYYY" (contoh: Jan 01, 2024)
    formatMMMMDDYYYY(date) {
        if (!date) return "";
        return format(new Date(date), "MMM dd, yyyy");
    },
};

// Register sebagai plugin Vue global
export default {
    install(Vue) {
        Vue.prototype.$dateTime = dateTime;
    },
};
