const trackerChildren = [
    {
        path: "dashboard",
        name: "dashboard",
        component: () =>
            import(
                /* webpackChunkName: "tracker-dashboard" */ "@/screens/dashboard/DashboardScreen.vue"
            ),
        meta: {
            title: "Dashboard",
        },
    },
    {
        path: "device",
        name: "DeviceList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-device" */ "@/screens/device/DeviceList.vue"
            ),
        meta: {
            title: "Device",
        },
    },
    {
        path: "device/add",
        name: "DeviceAdd",
        component: () =>
            import(
                /* webpackChunkName: "tracker-device" */ "@/screens/device/DeviceForm.vue"
            ),
        meta: {
            title: "Device",
        },
    },
    {
        path: "device/:id/edit",
        name: "DeviceEdit",
        component: () =>
            import(
                /* webpackChunkName: "tracker-device" */ "@/screens/device/DeviceForm.vue"
            ),
        meta: {
            title: "Device",
        },
    },
    {
        path: "user",
        name: "UserList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-personnel" */ "@/screens/user/UserList.vue"
            ),
        meta: {
            title: "User",
        },
    },
    {
        path: "user/add",
        name: "UserAdd",
        component: () =>
            import(
                /* webpackChunkName: "tracker-personnel" */ "@/screens/user/UserForm.vue"
            ),
        meta: {
            title: "User",
        },
    },
    {
        path: "user/:id/edit",
        name: "UserEdit",
        component: () =>
            import(
                /* webpackChunkName: "tracker-personnel" */ "@/screens/user/UserForm.vue"
            ),
        meta: {
            title: "User",
        },
    },
    {
        path: "target",
        name: "TargetList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-target" */ "@/screens/target/TargetList.vue"
            ),
        meta: {
            title: "Target",
        },
    },
    {
        path: "target/add",
        name: "TargetAdd",
        component: () =>
            import(
                /* webpackChunkName: "tracker-target" */ "@/screens/target/TargetForm.vue"
            ),
        meta: {
            title: "Target",
        },
    },
    {
        path: "target/:id/edit",
        name: "TargetEdit",
        component: () =>
            import(
                /* webpackChunkName: "tracker-target" */ "@/screens/target/TargetForm.vue"
            ),
        meta: {
            title: "Target",
        },
    },
    {
        path: "mission",
        name: "MissionList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-mission" */ "@/screens/mission/MissionList.vue"
            ),
        meta: {
            title: "Mission",
        },
    },
    {
        path: "mission/add",
        name: "MissionAdd",
        component: () =>
            import(
                /* webpackChunkName: "tracker-mission" */ "@/screens/mission/MissionForm.vue"
            ),
        meta: {
            title: "Mission",
        },
    },
    {
        path: "mission/:id/edit",
        name: "MissionEdit",
        component: () =>
            import(
                /* webpackChunkName: "tracker-mission" */ "@/screens/mission/MissionForm.vue"
            ),
        meta: {
            title: "Mission",
        },
    },
    {
        path: "geofences",
        name: "GeofencesList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-geofences" */ "@/screens/geofences/GeofencesList.vue"
            ),
        meta: {
            title: "Geofences",
        },
    },
    {
        path: "geofences/add",
        name: "GeofencesAdd",
        component: () =>
            import(
                /* webpackChunkName: "tracker-geofences" */ "@/screens/geofences/GeofencesForm.vue"
            ),
        meta: {
            title: "Geofences",
        },
    },
    {
        path: "geofences/:id/edit",
        name: "GeofencesEdit",
        component: () =>
            import(
                /* webpackChunkName: "tracker-geofences" */ "@/screens/geofences/GeofencesForm.vue"
            ),
        meta: {
            title: "Geofences",
        },
    },
    {
        path: "position",
        name: "PositionList",
        component: () =>
            import(
                /* webpackChunkName: "tracker-position" */ "@/screens/position/PositionList.vue"
            ),
        meta: {
            title: "Position",
        },
    },
    {
        path: "chat",
        name: "Chat",
        component: () =>
            import(
                /* webpackChunkName: "tracker-chat" */ "@/screens/chat/ChatIndex.vue"
            ),
        meta: {
            title: "Chat",
        },
    },
];

export default {
    path: "/tracker",
    component: () =>
        import(
            /* webpackChunkName: "tracker-layout" */ "@/screens/LayoutScreen.vue"
        ),
    meta: { requiresAuth: true },
    children: trackerChildren,
};
