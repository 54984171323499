import MockAuthService from "./mock/MockAuthService";
import ApiAuthService from "./api/ApiAuthService";

// Baca dari environment variable
const useMock = process.env.VUE_APP_USE_MOCK === "true";

// Atau bisa juga dari localStorage untuk development
// const useMock = localStorage.getItem('useMock') === 'true';

// Export instance dari service yang sesuai (mock atau real)
export default useMock ? new MockAuthService() : new ApiAuthService();
