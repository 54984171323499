export default class IAuthService {
    async login() {
        throw new Error("Not implemented");
    }
    async getProfile() {
        throw new Error("Not implemented");
    }
    async getRole() {
        throw new Error("Not implemented");
    }
    async getMenu() {
        throw new Error("Not implemented");
    }
    async getApps() {
        throw new Error("Not implemented");
    }
    async getPrivilege() {
        throw new Error("Not implemented");
    }
}
