import { firebaseChat } from "./firebase/FirebaseChatService";
import UserService from "./UserService";
import store from "@/store";

const userService = new UserService();

class ChatService {
    getCurrentUserId() {
        return store.state.auth.user?.userId || store.state.auth.userId;
    }

    async subscribeToUserChats(userId, callback, lastChat = null) {
        try {
            return await firebaseChat.subscribeToUserChats(
                userId,
                async (response) => {
                    if (response.success) {
                        const chatsWithDetails = await Promise.all(
                            response.data.map(async (chat) => {
                                const participantDetails = {};
                                await Promise.all(
                                    chat.participants.map(
                                        async (participantId) => {
                                            const userResponse =
                                                await userService.getDetail(
                                                    participantId
                                                );
                                            if (userResponse.success) {
                                                const {
                                                    name,
                                                    email,
                                                    profile_url,
                                                    administrator,
                                                    id,
                                                } = userResponse.data.data;
                                                participantDetails[
                                                    participantId
                                                ] = {
                                                    name,
                                                    email,
                                                    profile_url,
                                                    administrator,
                                                    id,
                                                };
                                            }
                                        }
                                    )
                                );

                                return {
                                    ...chat,
                                    participantDetails,
                                    unreadCounts: chat.unreadCounts || {},
                                };
                            })
                        );

                        callback({
                            success: true,
                            data: chatsWithDetails,
                            hasMore: response.hasMore,
                        });
                    } else {
                        callback({
                            success: false,
                            error: response.error,
                            hasMore: false,
                        });
                    }
                },
                lastChat
            );
        } catch (error) {
            console.error("Error in chat subscription:", error);
            throw error;
        }
    }

    async subscribeToUserStatus(userId, callback) {
        try {
            return await firebaseChat.subscribeToUserStatus(
                userId,
                (response) => {
                    callback(response);
                }
            );
        } catch (error) {
            console.error("Error in ChatService.subscribeToUserStatus:", error);
            throw error;
        }
    }

    /**
     * Membersihkan semua resources dan subscriptions
     */
    cleanup() {
        // Delegasikan cleanup ke FirebaseChatService
        firebaseChat.cleanup();
    }

    async updateUserStatus(userId, status) {
        try {
            await firebaseChat.updateUserStatus(userId, status);
        } catch (error) {
            console.error("Error in ChatService.updateUserStatus:", error);
            throw error;
        }
    }

    async subscribeToMessages(chatId, callback) {
        try {
            return await firebaseChat.subscribeToMessages(
                chatId,
                (response) => {
                    callback(response);
                }
            );
        } catch (error) {
            console.error("[Service] Error in subscribeToMessages:", error);
            throw error;
        }
    }

    /**
     * Mengirim pesan ke chat tertentu
     * @param {Object} messageData - Data pesan yang akan dikirim
     * @param {string} messageData.chatId - ID chat
     * @param {string} messageData.content - Isi pesan
     * @param {string} messageData.sender - ID pengirim
     * @param {string} messageData.type - Tipe pesan ('text', 'image', 'file')
     * @param {string} [messageData.fileUrl] - URL file (opsional)
     * @param {string} [messageData.fileName] - Nama file (opsional)
     * @param {string} [messageData.fileType] - Tipe file (opsional)
     */
    async sendMessage(messageData) {
        try {
            // Validasi data wajib
            if (
                !messageData.chatId ||
                !messageData.sender ||
                (!messageData.content && !messageData.fileUrl)
            ) {
                throw new Error("Missing required message data");
            }

            // Kirim ke Firebase
            const result = await firebaseChat.sendMessage(messageData.chatId, {
                content: messageData.content || "",
                sender: messageData.sender.toString(),
                type: messageData.type || "text",
                getCurrentUserId: messageData.getCurrentUserId, // Tambah getCurrentUserId
                participants: messageData.participants,
                ...(messageData.fileUrl && { fileUrl: messageData.fileUrl }),
                ...(messageData.fileName && { fileName: messageData.fileName }),
                ...(messageData.fileType && { fileType: messageData.fileType }),
            });

            return result;
        } catch (error) {
            console.error("❌ [Service] Error sending message:", error);
            throw error;
        }
    }

    async updateLastMessageReadBy(chatId, readBy) {
        try {
            return await firebaseChat.updateLastMessageReadBy(chatId, readBy);
        } catch (error) {
            console.error("Error updating lastMessage readBy:", error);
            throw error;
        }
    }

    async updateUnreadCounts(chatId, unreadCounts) {
        try {
            return await firebaseChat.updateUnreadCounts(chatId, unreadCounts);
        } catch (error) {
            console.error("Error updating unreadCounts:", error);
            throw error;
        }
    }

    async updateMessageReadBy(chatId, readBy) {
        try {
            return await firebaseChat.updateMessageReadBy(chatId, readBy);
        } catch (error) {
            console.error("Error updating messages readBy:", error);
            throw error;
        }
    }

    /**
     * Membuat chat baru atau mengembalikan chat yang sudah ada
     * @param {Object} chatData - Data untuk membuat chat baru
     * @param {Array} chatData.participants - Array berisi ID participants
     * @param {string} chatData.createdBy - ID user yang membuat chat
     * @returns {Promise<Object>} - Object chat yang dibuat atau yang sudah ada
     */
    async createChat(chatData) {
        try {
            // Validasi data wajib
            if (!chatData.participants || !chatData.createdBy) {
                throw new Error("Missing required chat data");
            }

            // Kirim ke Firebase
            const result = await firebaseChat.createChat({
                participants: chatData.participants.map((id) => id.toString()),
                createdBy: chatData.createdBy.toString(),
                type: "private",
                unreadCounts: chatData.participants.reduce((acc, id) => {
                    acc[id.toString()] = 0;
                    return acc;
                }, {}),
            });
            return result;
        } catch (error) {
            console.error("❌ [Service] Error creating chat:", error);
            throw error;
        }
    }

    /**
     * Menghapus chat dan semua pesannya
     * @param {string} chatId - ID chat yang akan dihapus
     * @returns {Promise<Object>} - Status operasi
     */
    async deleteChat(chatId) {
        try {
            const result = await firebaseChat.deleteChat(chatId);
            return result;
        } catch (error) {
            console.error("ChatService: Error deleting chat:", error);
            throw error;
        }
    }

    async loadMoreMessages(chatId, lastMessageTimestamp) {
        try {
            return await firebaseChat.loadMoreMessages(
                chatId,
                lastMessageTimestamp
            );
        } catch (error) {
            console.error("Error in ChatService.loadMoreMessages:", error);
            throw error;
        }
    }

    async createGroupChat(groupData) {
        try {
            // Validasi data wajib
            if (
                !groupData.groupName ||
                !groupData.participants ||
                groupData.participants.length < 3
            ) {
                console.warn("❌ Invalid group data:", {
                    hasName: !!groupData.groupName,
                    participantsCount: groupData.participants?.length,
                });
                throw new Error("Invalid group chat data");
            }

            // Pastikan semua data dalam format yang benar
            const sanitizedData = {
                groupName: groupData.groupName,
                groupAvatar: groupData.groupAvatar || null,
                type: "group",
                participants: groupData.participants.filter((id) => id),
                createdBy: groupData.createdBy,
                unreadCounts: {},
                createdAt: new Date(),
            };

            // Kirim ke Firebase
            const result = await firebaseChat.createGroupChat({
                ...sanitizedData,
                participants: sanitizedData.participants.map((id) =>
                    String(id)
                ),
                createdBy: String(sanitizedData.createdBy),
                unreadCounts: sanitizedData.participants.reduce((acc, id) => {
                    acc[String(id)] = 0;
                    return acc;
                }, {}),
            });

            return result;
        } catch (error) {
            console.error("❌ [Service] Error creating group chat:", error, {
                stack: error.stack,
            });
            throw error;
        }
    }
}

export default new ChatService();
