import Vue from "vue";
import Router from "vue-router";
import authRoutes from "./routes/auth.routes";
import trackerRoutes from "./routes/tracker.routes";
import { authGuard, handleError } from "./middleware/auth.middleware";

Vue.use(Router);

const routes = [
    {
        path: "/",
        redirect: "/tracker/dashboard",
    },
    ...authRoutes,
    trackerRoutes,
    {
        path: "*",
        name: "NotFound",
        component: () =>
            import(
                /* webpackChunkName: "error" */ "@/screens/errors/NotFound.vue"
            ),
    },
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) =>
        savedPosition || { x: 0, y: 0 },
});

// Middleware
router.beforeEach(authGuard);

// Error Handler
router.onError((error) => handleError(error, router));

export default router;
