import AuthService from "@/services/AuthService";
import cookie from "vue-cookie";
import chatService from "@/services/ChatService";

export default {
    namespaced: true,
    state: {
        loading: false,
        isAuthenticated: !!cookie.get("token"),
        user: {
            displayName: cookie.get("adminDisplayName") || "",
            jobTitle: cookie.get("adminJobTitle") || "",
            isAdmin: cookie.get("isAdmin") === "true",
            userId: cookie.get("userId") || "",
        },
        error: null,
        token: cookie.get("token") || null,
        userType: cookie.get("userType") || null,
        userId: cookie.get("userId") || "",
    },
    mutations: {
        SET_LOADING(state, status) {
            state.loading = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_AUTH(state, status) {
            state.isAuthenticated = status;
        },
        SET_USER(state, userData) {
            state.user = {
                ...userData,
                userId: userData.id || cookie.get("userId"),
            };
            // Set cookies
            cookie.set("adminDisplayName", userData.displayName);
            cookie.set("adminJobTitle", userData.jobTitle);
            cookie.set("isAdmin", userData.isAdmin);
            if (userData.id) {
                cookie.set("userId", userData.id);
            }
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER_TYPE(state, userType) {
            state.userType = userType;
        },
        SET_USER_ID(state, userId) {
            if (!userId) return;

            state.userId = userId.toString();
            state.user = {
                ...state.user,
                userId: userId.toString(),
            };
            cookie.set("userId", userId.toString());
        },
        CLEAR_AUTH(state) {
            state.isAuthenticated = false;
            state.user = {
                displayName: "",
                jobTitle: "",
                isAdmin: false,
                userId: "",
            };
            state.userId = "";
            // Clear cookies
            cookie.delete("token");
            cookie.delete("adminDisplayName");
            cookie.delete("adminJobTitle");
            cookie.delete("isAdmin");
            cookie.delete("userId");
        },
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                commit("SET_LOADING", true);
                commit("SET_ERROR", null);

                const loginResponse = await AuthService.login(
                    credentials.username,
                    credentials.password
                );

                if (!loginResponse.success) {
                    throw new Error(loginResponse.error);
                }

                const roleResponse = await AuthService.getRole();
                if (!roleResponse.success || !roleResponse.data?.rows) {
                    throw new Error("Failed to get user roles");
                }

                const roles = roleResponse.data.rows;
                if (
                    !roles.includes("Administrator") &&
                    !roles.includes("Personel")
                ) {
                    throw new Error("Invalid role");
                }

                const userData = JSON.parse(cookie.get("user"));
                const userId = userData.id;

                if (!userId) {
                    throw new Error("No user ID received from login");
                }

                // Set user status to online setelah login berhasil
                await chatService.updateUserStatus(userId, "online");

                commit("SET_AUTH", true);
                commit("SET_USER", {
                    displayName: userData.name,
                    jobTitle: roles[0],
                    isAdmin: roles.includes("Administrator"),
                    id: userId,
                });

                commit("SET_TOKEN", loginResponse.data.token);
                commit("SET_USER_TYPE", roles[0]);
                commit("SET_USER_ID", userId);

                return { success: true };
            } catch (error) {
                commit("SET_ERROR", error.message);
                commit("CLEAR_AUTH");
                return {
                    success: false,
                    error: error.message,
                };
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async logout({ commit, state }) {
            try {
                const userId = state.userId || state.user.userId;
                if (userId) {
                    // Set status offline
                    await chatService.updateUserStatus(userId, "offline");

                    // Cleanup semua subscriptions
                    await chatService.cleanup();
                }
            } catch (error) {
                console.error("Error during logout cleanup:", error);
            } finally {
                commit("CLEAR_AUTH");
            }
        },
        initializeStore({ commit }) {
            const userId = cookie.get("userId");
            if (userId) {
                commit("SET_USER_ID", userId);
            }
        },
    },
    getters: {
        isLoading: (state) => state.loading,
        isAuthenticated: (state) => state.isAuthenticated,
        currentUser: (state) => state.user,
        error: (state) => state.error,
        userType: (state) => state.userType,
        userId: (state) => state.userId || state.user.userId || "",
    },
};
