<template>
    <v-app>
        <transition>
            <router-view />
        </transition>
        <snackbar-alert />
    </v-app>
</template>

<script>
    import chatService from "@/services/ChatService";
    import SnackbarAlert from "@/components/common/SnackbarAlert.vue";
    import { throttle } from "lodash";

    export default {
        name: "App",

        components: {
            SnackbarAlert,
        },

        data: () => ({
            activityTimeout: null,
            inactivityDuration: 30 * 60 * 1000, // 30 menit
            isOnline: true,
            isVisible: true,
            isActive: true,
            lastStatusUpdate: null,
            statusUpdateDelay: 10000, // 10 detik delay minimum antara updates
        }),

        created() {
            // Setup all event listeners
            this.setupEventListeners();
            this.setupActivityTracking();
            this.checkInitialStatus();
        },

        beforeDestroy() {
            // Cleanup all event listeners
            this.removeEventListeners();
            this.cleanup();
        },

        methods: {
            setupEventListeners() {
                // Tab/Browser close
                window.addEventListener(
                    "beforeunload",
                    this.handleBeforeUnload
                );

                // Visibility change
                document.addEventListener(
                    "visibilitychange",
                    this.handleVisibilityChange
                );

                // Online/Offline status
                window.addEventListener("online", this.handleOnlineStatus);
                window.addEventListener("offline", this.handleOfflineStatus);

                // Window focus
                window.addEventListener("focus", this.handleWindowFocus);
                window.addEventListener("blur", this.handleWindowBlur);
            },

            removeEventListeners() {
                window.removeEventListener(
                    "beforeunload",
                    this.handleBeforeUnload
                );
                document.removeEventListener(
                    "visibilitychange",
                    this.handleVisibilityChange
                );
                window.removeEventListener("online", this.handleOnlineStatus);
                window.removeEventListener("offline", this.handleOfflineStatus);
                window.removeEventListener("focus", this.handleWindowFocus);
                window.removeEventListener("blur", this.handleWindowBlur);
                this.removeActivityListeners();
            },

            setupActivityTracking() {
                // Mouse & Keyboard events
                window.addEventListener("mousemove", this.resetActivityTimer);
                window.addEventListener("keypress", this.resetActivityTimer);

                // Touch events for mobile
                window.addEventListener("touchstart", this.resetActivityTimer);
                window.addEventListener("touchmove", this.resetActivityTimer);

                // Scroll events
                window.addEventListener("scroll", this.resetActivityTimer);

                this.resetActivityTimer();
            },

            removeActivityListeners() {
                window.removeEventListener(
                    "mousemove",
                    this.resetActivityTimer
                );
                window.removeEventListener("keypress", this.resetActivityTimer);
                window.removeEventListener(
                    "touchstart",
                    this.resetActivityTimer
                );
                window.removeEventListener(
                    "touchmove",
                    this.resetActivityTimer
                );
                window.removeEventListener("scroll", this.resetActivityTimer);
                clearTimeout(this.activityTimeout);
            },

            // Throttle update status untuk mengurangi request
            updateOnlineStatus: throttle(
                async function () {
                    const userId = this.$store.state.auth.user?.userId;
                    const now = Date.now();

                    // Cek apakah sudah waktunya update (minimal 10 detik dari update terakhir)
                    if (
                        userId &&
                        (!this.lastStatusUpdate ||
                            now - this.lastStatusUpdate >
                                this.statusUpdateDelay)
                    ) {
                        try {
                            await chatService.updateUserStatus(
                                userId,
                                "online"
                            );
                            this.lastStatusUpdate = now;
                        } catch (error) {
                            console.error("Error updating status:", error);
                        }
                    }
                },
                10000,
                { leading: true, trailing: true }
            ), // Ubah trailing menjadi true

            resetActivityTimer() {
                clearTimeout(this.activityTimeout);

                // Selalu update status saat ada aktivitas
                this.updateOnlineStatus();

                this.isActive = true;
                this.activityTimeout = setTimeout(
                    this.handleInactivity,
                    this.inactivityDuration
                );
            },

            async handleActivityResume() {
                this.updateOnlineStatus();
            },

            async handleInactivity() {
                this.isActive = false;
                await this.updateStatusToOffline("inactivity");
            },

            async handleVisibilityChange() {
                if (document.hidden) {
                    await this.updateStatusToOffline("visibility");
                } else {
                    this.updateOnlineStatus();
                    this.resetActivityTimer();
                }
            },

            async handleOnlineStatus() {
                this.isOnline = true;
                this.updateOnlineStatus();
                this.resetActivityTimer();
            },

            async handleOfflineStatus() {
                this.isOnline = false;
                await this.updateStatusToOffline("network");
            },

            async handleWindowFocus() {
                this.updateOnlineStatus();
                this.resetActivityTimer();
            },

            async handleWindowBlur() {
                await this.updateStatusToOffline("blur");
            },

            async handleBeforeUnload() {
                await this.updateStatusToOffline("unload");
            },

            async updateStatusToOffline(reason) {
                const userId = this.$store.state.auth.user?.userId;
                if (userId) {
                    try {
                        await chatService.updateUserStatus(userId, "offline");

                        if (reason === "inactivity") {
                            await chatService.cleanup();
                            await this.$store.dispatch("auth/logout");
                            this.$router.push("/login");
                        }
                    } catch (error) {
                        console.error(
                            `Error updating offline status (${reason}):`,
                            error
                        );
                    }
                }
            },

            async cleanup() {
                await this.updateStatusToOffline("cleanup");
            },

            async checkInitialStatus() {
                const userId = this.$store.state.auth.user?.userId;
                if (userId) {
                    try {
                        await chatService.updateUserStatus(userId, "online");
                        this.lastStatusUpdate = Date.now();
                    } catch (error) {
                        console.error("Error updating initial status:", error);
                    }
                }
            },
        },

        watch: {
            // Watch for auth state changes
            "$store.state.auth.user": {
                immediate: true,
                handler(newUser) {
                    if (newUser?.userId) {
                        this.checkInitialStatus();
                    }
                },
            },
        },
    };
</script>
