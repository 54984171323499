import Vue from "vue";

const Alert = new Vue({
    data() {
        return {
            message: "",
            type: "info",
        };
    },
    methods: {
        showMessage(message, type = "info") {
            this.message = message;
            this.type = type;
        },
        showError(message) {
            this.showMessage(message, "error");
        },
        showSuccess(message) {
            this.showMessage(message, "success");
        },
        showWarning(message) {
            this.showMessage(message, "warning");
        },
        showInfo(message) {
            this.showMessage(message, "info");
        },
    },
});

// Tambahkan ke window
window.$alert = Alert;

export default {
    install(Vue) {
        Vue.prototype.$alert = Alert;
    },
};
