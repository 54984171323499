import Vue from "vue";
import App from "./App.vue";
import "./assets/css/global.css";
import requiredLabel from "./directives/v-required-label";
import SnackbarAlert from "./components/common/SnackbarAlert.vue";
import AlertPlugin from "./plugins/alert";
import vuetify from "./plugins/vuetify";
import store from "./store";
import dateTimePlugin from "./utils/dateTime";

import router from "./router";
import JsonExcel from "vue-json-excel";

Vue.directive("required-label", requiredLabel);
Vue.component("SnackbarAlert", SnackbarAlert);
Vue.use(AlertPlugin);
Vue.use(dateTimePlugin);

Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;

var VueCookie = require("vue-cookie");
Vue.use(VueCookie);

Vue.filter("test", function () {
    return "Tost";
});

new Vue({
    router,
    store,
    created() {
        this.$store.dispatch("auth/initializeStore");
    },
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
