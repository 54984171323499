import httpClient from "@/utils/httpClient";

export default class UserService {
    async getList(params) {
        try {
            const response = await httpClient.post("users/list", params);
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            return {
                success: false,
                error: error.message,
            };
        }
    }

    async create(data) {
        try {
            const response = await httpClient.post("users", data);
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            return {
                success: false,
                error: error.message,
            };
        }
    }

    async getDetail(id) {
        try {
            const response = await httpClient.get(`users/${id}`);
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            return {
                success: false,
                error: error.message,
            };
        }
    }

    async update(id, data) {
        try {
            const response = await httpClient.put(`users/${id}`, data);
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            return {
                success: false,
                error: error.message,
            };
        }
    }

    async delete(id) {
        try {
            const response = await httpClient.delete(`users/${id}`);

            if (response.data.rcode === 0) {
                return {
                    success: false,
                    error: response.data.rmessage || "Failed to delete data",
                };
            }

            return {
                success: true,
                data: response.data,
                message: "Data has been deleted successfully",
            };
        } catch (error) {
            console.error("PersonnelService - Delete error:", {
                message: error.message,
                response: error.response?.data,
            });
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    async getActiveUsers(params) {
        try {
            const response = await httpClient.post("users/list/active", params);
            return {
                success: response.data.rcode === 1,
                data: {
                    data: response.data.rows || [], // array of users
                    meta: {
                        total: response.data.total,
                        current_page: params.page,
                        last_page: Math.ceil(
                            response.data.total / params.limit
                        ),
                    },
                },
            };
        } catch (error) {
            console.error("GetActiveUsers Error:", error);
            return {
                success: false,
                error: error.response?.data?.rmessage || error.message,
            };
        }
    }

    // Get user devices
    async getUserDevices(userId, params) {
        try {
            const response = await httpClient.get(`users/${userId}/devices`, {
                params,
            });
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            console.error("UserService - Get User Devices error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    // Assign devices to user
    async assignDevices(userId, deviceIds) {
        try {
            const response = await httpClient.post(`users/${userId}/devices`, {
                device_id: deviceIds,
            });
            return {
                success: true,
                data: response.data,
                message: "Devices assigned successfully",
            };
        } catch (error) {
            console.error("UserService - Assign Devices error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    // Remove device from user
    async removeDevice(userId, deviceId) {
        try {
            const response = await httpClient.delete(
                `users/${userId}/devices/${deviceId}`
            );
            return {
                success: true,
                data: response.data,
                message: "Device removed successfully",
            };
        } catch (error) {
            console.error("UserService - Remove Device error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    // Get user missions
    async getUserMissions(userId, params) {
        try {
            const response = await httpClient.get(`users/${userId}/groups`, {
                params,
            });
            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            console.error("UserService - Get User Missions error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    // Assign missions to user
    async assignMissions(userId, missionIds) {
        try {
            const response = await httpClient.post(`users/${userId}/groups`, {
                group_id: missionIds,
            });
            return {
                success: true,
                data: response.data,
                message: "Missions assigned successfully",
            };
        } catch (error) {
            console.error("UserService - Assign Missions error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }

    // Remove mission from user
    async removeMission(userId, missionId) {
        try {
            const response = await httpClient.delete(
                `users/${userId}/groups/${missionId}`
            );
            return {
                success: true,
                data: response.data,
                message: "Mission removed successfully",
            };
        } catch (error) {
            console.error("UserService - Remove Mission error:", error);
            return {
                success: false,
                error: error.response?.data?.message || error.message,
            };
        }
    }
}
