export default [
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "auth" */ "@/screens/auth/Login.vue"),
        meta: { guest: true },
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "@/screens/auth/Register.vue"
            ),
        meta: { guest: true },
    },
    {
        path: "/register-verification-code",
        name: "RegisterVerificationCode",
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "@/screens/auth/RegisterVerificationCode.vue"
            ),
        meta: { guest: true },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "@/screens/auth/ForgotPassword.vue"
            ),
        meta: { guest: true },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "@/screens/auth/ResetPassword.vue"
            ),
        meta: { guest: true },
    },
];
